import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useGoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import i1 from "../assets/img/pdf-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/fontawesome-free-solid";
import Footer from "../component/Footer";
import Loader from "../component/Loader";

const Meeshocrop = () => {
  const [file, setfile] = useState([]);
  const [loading, setloading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [showlogin, setshowlogin] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [dtoken, setdtoken] = useState({});
  const [pdfLinks, setpdfLinks] = useState([]);
  const [filepre, setfilepre] = useState([]);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFiles = Array.from(e.target.files);
      newFiles.forEach((file) => {
        setfile((prevFiles) => [...prevFiles, file]);
        setfilepre((pre) => [...pre, file]);
      });
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => setAccessToken(tokenResponse.access_token),
  });

  useEffect(() => {
    if (accessToken !== "") {
      fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${accessToken}`
      )
        .then((res) => res.json())
        .then((result) => {
          const uData = {
            uemail: result.email,
            uname: result.name,
            upicture: result.picture,
          };
          axios
            .post(`${process.env.REACT_APP_API_URL}/api/login`, uData)
            .then((res) => {
              setCookie("token", res.data.token);
              setshowlogin(false);
              setdtoken({ name: res.data.name });
              const data = { id: res.data.id };
              getDownloadLink(data);
            });
        });
    }
  }, [accessToken, setCookie]);

  useEffect(() => {
    const stoken = cookies.token;
    if (stoken !== undefined) {
      setshowlogin(false);
      const decoded = jwtDecode(stoken);
      setdtoken(decoded);
    }
  }, [cookies, setdtoken]);

  const getDownloadLink = async (data) => {
    const url = `${process.env.REACT_APP_API_URL}/api/pdflinks`;
    await axios.post(url, data).then((res) => {
      if (res.data.success === true) {
        setpdfLinks(res.data.pdf_info);
      }
    });
  };

  const Pdfcrop = async (event) => {
    event.preventDefault();
    const userId = dtoken.user_id;
    if (file.length > 0 && userId !== undefined) {
      setloading(true);
      try {
        // const promises = file.map(async (formData) => {

        // });
        const formData = new FormData();
        file.forEach((file) => {
          formData.append("files", file);
        });
        // console.log(typeof formData, formData);
        await fetch(`${process.env.REACT_APP_API_URL}/upload/${userId}`, {
          method: "POST",
          body: formData,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res && res.success) {
              setloading(false);
              setfile([]);
              const data = { id: userId };
              getDownloadLink(data);
              setfilepre([]);
            } else {
              alert("Upload failed: No pages found to crop");
            }
          });
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    }
  };

  useEffect(() => {
    if (dtoken.user_id !== undefined) {
      const data = {
        id: dtoken.user_id,
      };
      getDownloadLink(data);
    }
  }, [dtoken]);

  const downloadPdf = async (e, uuid) => {
    e.preventDefault();
    const data = {
      uuid: uuid,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/download`,
        data,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Meesho_" + uuid + ".pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handellogout = () => {
    removeCookie("token", { path: "/", domain: "localhost" });
    setshowlogin(true);
  };

  const deletepdf = (e, ind) => {
    e.preventDefault();

    if (ind > -1) {
      let data = [...filepre];
      data.splice(ind, 1);
      setfilepre(data);
    }
  };

  return (
    <>
      {loading === true ? (
        <>
          {" "}
          <Loader />
        </>
      ) : (
        <></>
      )}
      <div className="loading-box">
        <header
          id="header"
          className="header fixed-top bg-white"
          data-scrollto-offset="0"
        >
          <div className="container-fluid d-flex align-items-center justify-content-between">
            <Link
              to="/"
              className="logo d-flex align-items-center scrollto me-auto me-lg-0"
            >
              <h1>
                LabelCropper<span>.</span>
              </h1>
            </Link>
            <nav
              id="navbar"
              className={showlogin === true ? "navbar" : "navbar ms-auto"}
            >
              <ul>
                <li>
                  <Link className="nav-link scrollto" to="/">
                    <span>Home</span>
                  </Link>
                </li>
                <li>
                  <Link className="nav-link scrollto" to="/">
                    Services
                  </Link>
                </li>
                <li>
                  <Link className="nav-link scrollto" to="/">
                    Contact
                  </Link>
                </li>
                {/* <li className="dropdown">
                <a href="/">
                  <span>PDF Tools</span>{" "}
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <a href="">Merge PDF</a>
                  </li>
                </ul>
              </li> */}
                <li className="dropdown">
                  <a href="/">
                    <span>E-commerce</span>
                    <i className="bi bi-chevron-down dropdown-indicator"></i>
                  </a>
                  <ul>
                    <li>
                      <Link to="/meesholabel">Meesho Label Crop</Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle d-none"></i>
            </nav>
            {showlogin === true ? (
              <>
                <a
                  href="/"
                  className="btn-getstarted cursor-pointer"
                  onClick={() => login()}
                >
                  Login/Register
                </a>
              </>
            ) : (
              <>
                <p className="ms-auto me-4 pt-3">Hello, {dtoken.name}</p>

                <a
                  href="/"
                  className="btn-logout cursor-pointer"
                  onClick={() => handellogout()}
                >
                  Logout
                </a>
              </>
            )}
          </div>
        </header>
        <main id="main">
          <div className="breadcrumbs">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <h2>Crop Meesho Label</h2>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row p-3">
              <div className="col-md-3 p-3">
                <div className="row">
                  <figure>
                    <blockquote className="blockquote">
                      <h4>Upcoming Features</h4>
                    </blockquote>
                  </figure>
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item"> Unlimited Usage</li>
                    <li className="list-group-item"> Add Date Time on Label</li>
                    <li className="list-group-item"> Remove Extra Pages</li>
                    <li className="list-group-item">
                      {" "}
                      Merge Multiple Account's Labels
                    </li>
                  </ol>
                </div>
              </div>
              <div
                className="col-md-6 p-3"
                style={{
                  borderLeft: "dotted 0.5px black",
                  borderRight: " dotted 0.5px black",
                }}
              >
                <div className="row">
                  <h1> Upload your files </h1>
                  <p className="login-txt">
                    Crop your meesho PDF Labels in the order you want with the
                    LabelCropper
                  </p>
                  <div className="content home">
                    {showlogin === true ? (
                      <>
                        <div>
                          <p> Login with Google to access this tool</p>
                        </div>
                        <div className="p-5 text-center">
                          <button
                            type="button"
                            className="login-with-google-btn bg-white shadow"
                            onClick={() => login()}
                          >
                            Sign in with Google
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        {loading === true ? (
                          <>
                            {" "}
                            <div>
                              <form encType="multipart/form-data">
                                <label
                                  htmlFor="images"
                                  className="drop-container"
                                  id="dropcontainer"
                                  multiple="multiple"
                                  // onChange={handleFileChange}
                                >
                                  <span className="drop-title">
                                    Drop files here
                                  </span>
                                  or
                                  <input
                                    type="file"
                                    id="images"
                                    onChange={handleFileChange}
                                    required
                                    multiple="multiple"
                                    disabled
                                  />
                                </label>
                              </form>
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div>
                              <form>
                                <label
                                  htmlFor="images"
                                  className="drop-container"
                                  id="dropcontainer"
                                  multiple="multiple"
                                >
                                  <span className="drop-title">
                                    Drop files here
                                  </span>
                                  or
                                  <input
                                    type="file"
                                    id="images"
                                    onChange={handleFileChange}
                                    required
                                    name="file"
                                    multiple="multiple"
                                  />
                                </label>
                              </form>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <div className="row">
                      {loading === true ? (
                        <>
                          {" "}
                          {filepre &&
                            filepre.map((val, ind) => (
                              <div
                                className="col-lg-2 p-4 overflow-hidden"
                                key={ind}
                              >
                                <div className="img-prevew-cont">
                                  <div className="img-prevew-box position-relative">
                                    <img
                                      src={i1}
                                      className="img-fluid"
                                      alt="i1"
                                    />
                                  </div>
                                </div>
                                <p className="text-secondary ">{val.name}</p>
                              </div>
                            ))}
                        </>
                      ) : (
                        <>
                          {filepre &&
                            filepre.map((val, ind) => (
                              <div
                                className="col-lg-2 p-4 overflow-hidden"
                                key={ind}
                              >
                                <div className="img-prevew-cont">
                                  <div className="img-prevew-box position-relative">
                                    <img
                                      src={i1}
                                      className="img-fluid"
                                      alt="i1"
                                    />
                                    <div className=" position-absolute top-0 start-100">
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="fs-6"
                                        onClick={(e) => deletepdf(e, ind)}
                                        title="Delete pdf"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="text-secondary ">{val.name}</p>
                              </div>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 p-3">
                <div className="row">
                  <div
                    className="text-center startCropBtn"
                    //  style="display:none;"
                  >
                    {file.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        {loading === true ? (
                          <>
                            <button
                              className="btn btn-success crop_label"
                              type="button"
                              disabled
                            >
                              <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Loading...
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn btn-success crop_label"
                              onClick={(e) => Pdfcrop(e)}
                            >
                              Process
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <hr />
                <figure>
                  <blockquote className="blockquote overflow-y-auto">
                    <h4>Cropped Files</h4>
                    <div>
                      {pdfLinks &&
                        pdfLinks.map((val, ind) => (
                          <React.Fragment key={ind}>
                            <div className="d-flex p-2">
                              <p className="fs-6">Pages: {val.page_count}</p>
                              <p
                                className="pdfDLink fs-6"
                                onClick={(e) => downloadPdf(e, val.file_uuid)}
                              >
                                Meesho_{val.file_uuid}.pdf
                              </p>
                            </div>
                          </React.Fragment>
                        ))}
                    </div>
                  </blockquote>
                </figure>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
};

export default Meeshocrop;
