import React, { useEffect, useState } from "react";
import i1 from "../assets/img/hero-carousel/hero-carousel-3.svg";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Footer from "../component/Footer";
import Questions from "../component/Questions";

const Homepage = () => {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [showlogin, setshowlogin] = useState(true);
  const token = cookies.token;
  const [accessToken, setAccessToken] = useState("");
  const [dtoken, setdtoken] = useState({});

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => setAccessToken(tokenResponse.access_token),
  });

  useEffect(() => {
    if (accessToken !== "") {
      fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${accessToken}`
      )
        .then((res) => res.json())
        .then((result) => {
          const uData = {
            uemail: result.email,
            uname: result.name,
            upicture: result.picture,
          };
          axios
            .post(`${process.env.REACT_APP_API_URL}/api/login`, uData)
            .then((res) => {
              setCookie("token", res.data.token);
              setshowlogin(false);
              setdtoken({ name: res.data.name });
            });
        });
    }
  }, [accessToken, setCookie]);

  useEffect(() => {
    if (token !== undefined) {
      setshowlogin(false);
    }
  }, [token]);

  useEffect(() => {
    const stoken = cookies.token;
    if (stoken !== undefined) {
      setshowlogin(false);
      const decoded = jwtDecode(stoken);
      setdtoken(decoded);
    }
  }, [cookies, setdtoken]);

  const handellogout = () => {
    removeCookie("token", { path: "/", domain: "localhost" });
    setshowlogin(true);
  };
  return (
    <>
      <header
        id="header"
        className="header fixed-top bg-white"
        data-scrollto-offset="0"
      >
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <Link
            to="/"
            className="logo d-flex align-items-center scrollto me-auto me-lg-0"
          >
            <h1>
              LabelCropper<span>.</span>
            </h1>
          </Link>
          <nav
            id="navbar"
            className={showlogin === true ? "navbar" : "navbar ms-auto"}
          >
            <ul>
              <li>
                <a className="nav-link scrollto" href="#home">
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#Service">
                  Services
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#Contact">
                  Contact
                </a>
              </li>
              <li className="dropdown">
                <a href="/">
                  <span>E-commerce</span>
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <Link to="/meesholabel">Meesho Label Crop</Link>
                  </li>
                </ul>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle d-none"></i>
          </nav>
          {showlogin === true ? (
            <>
              <div
                className="btn-getstarted cursor-pointer"
                onClick={() => login()}
              >
                Login/Register
              </div>
            </>
          ) : (
            <>
              <p className="ms-auto me-4 pt-3" href="#Contact">
                Hello, {dtoken.name}
              </p>
              <div
                className="btn-logout cursor-pointer"
                onClick={() => handellogout()}
              >
                Logout
              </div>
            </>
          )}
        </div>
      </header>
      {/* End Header */}
      <section
        id="hero-animated"
        className="hero-animated d-flex align-items-center"
      >
        <div
          className="container d-flex flex-column justify-content-center align-items-center text-center position-relative"
          data-aos="zoom-out"
          id="home"
        >
          <img src={i1} className="img-fluid animated" alt="Hero" />
          <h2>
            Welcome to <span>LabelCropper</span>
          </h2>
          <p>Save Your time in Rocket Speed to Crop Your E-Commerce Labels</p>
          <div className="d-flex">
            <a href="#featured-services" className="btn-get-started scrollto">
              Get Started
            </a>
          </div>
        </div>
      </section>

      <main id="main">
        {/* <!-- ======= Featured Services Section ======= --> */}
        <section id="featured-services" className="featured-services">
          <div className="container" id="Service">
            <div className="row gy-4">
              <div className="col-xl-3 col-md-6 d-flex" data-aos="zoom-out">
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="bi bi-activity icon"></i>
                  </div>
                  <h4>
                    <a href="/" className="stretched-link">
                      Print Date time on Label
                    </a>
                  </h4>
                  <p>
                    Save Your time in Rocket Speed to Crop Your E-Commerce
                    Lables
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-xl-3 col-md-6 d-flex"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="bi bi-bounding-box-circles icon"></i>
                  </div>
                  <h4>
                    <a href="/" className="stretched-link">
                      Merge PDF Files
                    </a>
                  </h4>
                  <p>
                    Print Date and Time of converting files on each label page
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-xl-3 col-md-6 d-flex"
                data-aos="zoom-out"
                data-aos-delay="400"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="bi bi-calendar4-week icon"></i>
                  </div>
                  <h4>
                    <a href="/" className="stretched-link">
                      Multi Order at Bottom
                    </a>
                  </h4>
                  <p>
                    Keep Multi Order's label on bottom of generated output files
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-xl-3 col-md-6 d-flex"
                data-aos="zoom-out"
                data-aos-delay="600"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="bi bi-broadcast icon"></i>
                  </div>
                  <h4>
                    <a href="/" className="stretched-link">
                      Generate Order Summary
                    </a>
                  </h4>
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}
            </div>
          </div>
        </section>
        {/* <!-- End Featured Services Section --> */}

        {/* <!-- ======= Clients Section ======= --> */}
        <section id="clients" className="clients">
          <div className="container" data-aos="zoom-out"></div>
        </section>
        {/* <!-- End Clients Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features">
          <div className="container" data-aos="fade-up">
            <ul className="nav nav-tabs row gy-4 d-flex">
              <li className="nav-item col-6 col-md-4 col-lg-2">
                <a
                  href="/"
                  className="nav-link active show"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-1"
                >
                  <i className="bi bi-binoculars color-cyan"></i>
                  <h4>Modinest</h4>
                </a>
              </li>
              {/* <!-- End Tab 1 Nav --> */}

              <li className="nav-item col-6 col-md-4 col-lg-2">
                <a
                  href="/"
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-2"
                >
                  <i className="bi bi-box-seam color-indigo"></i>
                  <h4>Undaesenti</h4>
                </a>
              </li>
              {/* <!-- End Tab 2 Nav --> */}

              <li className="nav-item col-6 col-md-4 col-lg-2">
                <a
                  href="/"
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-3"
                >
                  <i className="bi bi-brightness-high color-teal"></i>
                  <h4>Pariatur</h4>
                </a>
              </li>
              {/* <!-- End Tab 3 Nav --> */}

              <li className="nav-item col-6 col-md-4 col-lg-2">
                <a
                  href="/"
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-4"
                >
                  <i className="bi bi-command color-red"></i>
                  <h4>Nostrum</h4>
                </a>
              </li>
              {/* <!-- End Tab 4 Nav --> */}

              <li className="nav-item col-6 col-md-4 col-lg-2">
                <a
                  href="/"
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-5"
                >
                  <i className="bi bi-easel color-blue"></i>
                  <h4>Adipiscing</h4>
                </a>
              </li>
              {/* <!-- End Tab 5 Nav --> */}

              <li className="nav-item col-6 col-md-4 col-lg-2">
                <a
                  href="/"
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-6"
                >
                  <i className="bi bi-map color-orange"></i>
                  <h4>Reprehit</h4>
                </a>
              </li>
              {/* <!-- End Tab 6 Nav --> */}
            </ul>
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        <Questions />

        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" className="contact">
          <div className="container" id="Contact">
            <div className="section-header">
              <h2>Contact Us</h2>
            </div>
          </div>
          <div className="container">
            <div className="row gy-5 gx-lg-5">
              <div className="col-lg-4">
                <div className="info">
                  <h3>Get in touch</h3>

                  <div className="info-item d-flex">
                    <i className="bi bi-geo-alt flex-shrink-0"></i>
                    <div>
                      <h4>Location:</h4>
                      <p>
                        G-303, Yash Plaza, Maninagar <br />
                        Ahmedabad - 390015, India
                      </p>
                    </div>
                  </div>
                  {/* <!-- End Info Item --> */}

                  <div className="info-item d-flex">
                    <i className="bi bi-envelope flex-shrink-0"></i>
                    <div>
                      <h4>Email:</h4>
                      <p>info@labelcropper.in</p>
                    </div>
                  </div>
                  {/* <!-- End Info Item --> */}

                  <div className="info-item d-flex">
                    <i className="bi bi-phone flex-shrink-0"></i>
                    <div>
                      <h4>Call:</h4>
                      <p>+1 9292 6945 47</p>
                    </div>
                  </div>
                  {/* <!-- End Info Item --> */}
                </div>
              </div>

              <div className="col-lg-8">
                <form
                  // action="forms/contact.php"
                  // method="post"
                  // role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
              {/* <!-- End Contact Form --> */}
            </div>
          </div>
        </section>
        {/* <!-- End Contact Section --> */}
      </main>
      {/* <!-- End #main --> */}
      <Footer />
    </>
  );
};

export default Homepage;
