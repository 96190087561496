import React from "react";

const Footer = () => {
  return (
    <>
      {/* <!-- ======= Footer ======= -->  */}
      <footer id="footer" className="footer">
        <div className="footer-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <h3>LabelCropper</h3>
                  <p>
                    G-303, Yash Plaza, Maninagar <br />
                    Ahmedabad - 390015, India
                    <br />
                    <br />
                    <strong>Phone:</strong> +1 92992 6945 47
                    <br />
                    <strong>Email:</strong> info@labelcropper.in
                    <br />
                  </p>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Legal</h4>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/">About Us</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/">Terms of service</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/">Privacy policy</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/">Refund & Cancellation</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/">Crop Meesho Labels</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/">Crop Glowroad Labels</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/">Crop Flipart Labels</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/">Crop Amazon Labels</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/">Coming Soon</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 footer-newsletter">
                <h4>Our Newsletter</h4>
                <p>Subscribe our Newsletter to get updates</p>
                <form action="" method="post">
                  <input type="email" name="email" />
                  <input type="submit" value="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-legal text-center">
          <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
            <div className="d-flex flex-column align-items-center align-items-lg-start">
              <div className="copyright">
                &copy; Copyright{" "}
                <strong>
                  <span>LabelCropper</span>
                </strong>
                . All Rights Reserved
              </div>
            </div>

            <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
              <a href="/" className="twitter">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="/" className="facebook">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="/" className="instagram">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="/" className="google-plus">
                <i className="bi bi-skype"></i>
              </a>
              <a href="/" className="linkedin">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- End Footer --> */}
    </>
  );
};

export default Footer;
